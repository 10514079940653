import React from 'react'
import AdSense from 'react-adsense'

const AdBlogBottomLb = () => {
  return <AdSense.Google client="ca-pub-0654853136410544" slot="2654638322" />
}

export default AdBlogBottomLb

// <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
// <!-- mh_gj_bottom_lb -->
// <ins class="adsbygoogle"
//      style="display:inline-block;width:728px;height:90px"
//      data-ad-client="ca-pub-0654853136410544"
//      data-ad-slot="2812039053"></ins>
// <script>
// (adsbygoogle = window.adsbygoogle || []).push({});
// </script>
