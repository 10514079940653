import React from 'react'
import AdSense from 'react-adsense'

const AdBlogSidebarLr = () => {
  return <AdSense.Google client="ca-pub-0654853136410544" slot="2932619844" />
}

export default AdBlogSidebarLr

{
  /* <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<!-- mh_gj_sidebar_lr -->
<ins class="adsbygoogle"
     style="display:inline-block;width:336px;height:280px"
     data-ad-client="ca-pub-0654853136410544"
     data-ad-slot="1494377517"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script> */
}
