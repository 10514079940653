import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import BlogLayout from '../components/BlogLayout'

const styles = {
  postContainer: {
    width: '80%',
    minWidth: 350,
  },
  dateText: {
    color: 'grey',
  },
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    return (
      <BlogLayout>
        <Helmet>
          <title> { post.frontmatter.seoTitle || post.frontmatter.title}</title>
            {post.frontmatter.metaDescription &&  <meta name="description" content={post.frontmatter.metaDescription} />}
        </Helmet>
        <div style={styles.postContainer}>
          <h1 style={{ fontSize: 36 }}>{post.frontmatter.title}</h1>
          <p style={styles.dateText}>Published on {post.frontmatter.date}</p>
          <br />
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <br />
          <br />
          <Link to="../blog">
            <p>&larr; All blog posts</p>
          </Link>
        </div>
      </BlogLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(frontmatter: { permalink: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        permalink
        seoTitle
        metaDescription
      }
    }
  }
`
