import React from 'react'
import Helmet from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.min.css'

import Header from './Header'
import Footer from './Footer'
import AdBlogSidebarLr from './ads/blog/AdBlogSidebarLr'
import AdBlogBottomLb from './ads/blog/AdBlogBottomLb'
import { showAds } from './../utils/utils'
import Sidebar from './Sidebar'

const styles = {
  rootContainer: {
    // backgroundColor: 'blue',
    padding: 10,
  },
  bodyContainer: {
    // backgroundColor: 'red',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sidebarContainer: {},
  adContainerBottom: {
    marginTop: 10,
  },
  sidebarAdContainer: {
    marginBottom: 10,
    marginTop: 10,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'yellow',
  },
}

class Layout extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { children } = this.props
    return (
      <div className="container-fluid" style={{ padding: 0, margin: 0 }}>
        <Helmet>
          <html lang="en" />
          <title>mHotspot - Turn your laptop into wifi hotspot</title>
          <meta
            name="keywords"
            content="virtual wifi router, wifi hotspot creator, windows hotspot software, public wifi hotspot, connectify alternative"
          />
          <meta
            name="description"
            content="Free software to use your windows computer/laptop as virtual wifi router and create a free wifi hotspot."
          />
        </Helmet>
        <div className="row">
          <div className="col-12">
            <Header />
          </div>
        </div>
        <div className="row" style={styles.rootContainer}>
          <div className="container col-10">
            <div className="row">
              <div className="col-sm-9" style={styles.bodyContainer}>
                {children} {/* blog post goes here*/}
                {showAds() && (
                  <div style={styles.adContainerBottom}>
                    <AdBlogBottomLb />
                  </div>
                )}
              </div>
              <div className="col-sm-3" style={styles.sidebarContainer}>
                {showAds() && (
                  <div style={styles.sidebarAdContainer}>
                    <h3>advertisement</h3>
                    <AdBlogSidebarLr />
                  </div>
                )}
                <Sidebar />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Footer />
          </div>
        </div>
        {showAds() && (
          <script
            async
            src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          />
        )}
      </div>
    )
  }
}

export default Layout
